import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import NotificationService from '../../services/notificationService';

// Async thunks for API calls
export const fetchNotificationCount = createAsyncThunk(
  'notifications/fetchCount',
  async (userId) => {
    try {
      const response = await NotificationService.getEmailHistoryNotRead(userId, 1);
      // Log the response to see what we're getting
      console.log('Notification count response:', response.data);
      
      // Check if response.data.data is an array and get NotReadEmails from first item
      if (Array.isArray(response.data.data) && response.data.data.length > 0) {
        return response.data.data[0].NotReadEmails || 0;
      }
      // If it's a direct count value
      return parseInt(response.data.data) || 0;
    } catch (error) {
      console.error('Error fetching notification count:', error);
      return 0;
    }
  }
);

export const fetchNotifications = createAsyncThunk(
  'notifications/fetchAll',
  async (userId) => {
    const response = await NotificationService.getEmailHistoryNotRead(userId, 0);
    return response.data.data;
  }
);

export const markNotificationAsRead = createAsyncThunk(
  'notifications/markAsRead',
  async ({ emailHistoryId, loginUserId }) => {
    await NotificationService.updateEmailHistoryIsRead(emailHistoryId, loginUserId);
    return emailHistoryId;
  }
);

const notificationSlice = createSlice({
  name: 'notifications',
  initialState: {
    count: 0,
    notifications: [],
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle fetchNotificationCount
      .addCase(fetchNotificationCount.fulfilled, (state, action) => {
        state.count = action.payload;
        state.loading = false;
      })
      .addCase(fetchNotificationCount.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNotificationCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Handle fetchNotifications
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.notifications = action.payload;
        state.loading = false;
      })
      .addCase(fetchNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Handle markNotificationAsRead
      .addCase(markNotificationAsRead.fulfilled, (state, action) => {
        state.notifications = state.notifications.map(notification =>
          notification.EmailHistoryId === action.payload
            ? { ...notification, IsRead: 1 }
            : notification
        );
        state.count = Math.max(0, state.count - 1);
        state.loading = false;
      });
  }
});

export default notificationSlice.reducer;