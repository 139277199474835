import { configureStore } from "@reduxjs/toolkit";
import fundsSlice from "../pages/funds/fundsSlice";
import frequencyEditRowSlice from "../pages/manage_frequency/frequencyEditRowSlice";
import coaSlice from "../pages/coa/coaMapping/coaSlice";
import configureSlice from "../pages/configureWorkflow/configureSlice";
import editableTableSlice from "../components/r_editable_table/editableTableSlice";
import datasetMappingSlice from "../pages/datasetMapping/datasetMappingSlice";
import notificationReducer from "../layouts/header/notificationSlice";
// export const store = configureStore({
//   reducer: {
//     editRow: frequencyEditRowSlice,
//     funds: fundsSlice,
//     coa: coaSlice,
//     configure: configureSlice,
//     editableTable: editableTableSlice,
//     datasetMapping:datasetMappingSlice,
//   },
// });


// Save state to localStorage
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('reduxState', serializedState);
  } catch (error) {
    console.error('Error saving state to localStorage:', error);
  }
};

// Load state from localStorage
const loadState = () => {
  try {
    const serializedState = localStorage.getItem('reduxState');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    console.error('Error loading state from localStorage:', error);
    return undefined;
  }
};

export const store = configureStore({
  reducer: {
    editRow: frequencyEditRowSlice,
    funds: fundsSlice,
    coa: coaSlice,
    configure: configureSlice,
    editableTable: editableTableSlice,
    datasetMapping:datasetMappingSlice,
    notifications: notificationReducer
  },
  preloadedState: loadState(), // Initialize Redux store with persisted state
});

// Subscribe to store changes and save to localStorage
store.subscribe(() => {
  saveState(store.getState());
});
