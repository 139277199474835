import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { Link, Route, useNavigate, useLocation, } from 'react-router-dom';
import { useMsal, useAccount } from '@azure/msal-react';
//import msalInstance from '../../configs/msalConfig';

import UsersService from '../../services/usersService';


const Login = ({ instance, url }) => {
  //const { instance } = useMsal();
  const [userDetails, setUserDetails] = useState({});
  const [ipAddress, setIpAddress] = useState("");

  const account = useAccount();
  const fromUrl = url?.pathname + url?.search || null;
  useEffect(() => {
    const login = async () => {
      try {
        await instance.handleRedirectPromise();
        if (!instance.getActiveAccount()) {

          await instance.loginRedirect();
        }
      } catch (error) {
        console.log(error);
      }
    };

    login();
  }, [instance]);

  const getIPData = async () => {
    const res = await axios.get('https://api.ipify.org?format=json')
    setIpAddress(res.data.ip)
    localStorage.setItem('data', JSON.stringify(res.data))
  }
  useEffect(() => {
    // Get browser and OS details
    const getBrowserDetails = () => {
      const userAgent = navigator.userAgent;
      const platform = navigator.platform;

      // // Determine Browser Name and Version
      // const browserMatch = userAgent.match(/(Firefox|Edg|Chrome|Safari|Opera|OPR)\/(\d+\.\d+)/);
      // const browserName = browserMatch ? browserMatch[1].replace("OPR", "Opera") : "Unknown";
      // const browserVersion = browserMatch ? browserMatch[2] : "Unknown";
      let browserName = "Unknown";
      let browserVersion = "Unknown";
    
      if (/Edg\/(\d+\.\d+)/.test(userAgent)) {
        browserName = "Edge";
        browserVersion = userAgent.match(/Edg\/(\d+\.\d+)/)[1];
      } else if (/Chrome\/(\d+\.\d+)/.test(userAgent)) {
        browserName = "Chrome";
        browserVersion = userAgent.match(/Chrome\/(\d+\.\d+)/)[1];
      } else if (/Firefox\/(\d+\.\d+)/.test(userAgent)) {
        browserName = "Firefox";
        browserVersion = userAgent.match(/Firefox\/(\d+\.\d+)/)[1];
      } else if (/Safari\/(\d+\.\d+)/.test(userAgent) && !/Chrome/.test(userAgent)) {
        browserName = "Safari";
        browserVersion = userAgent.match(/Version\/(\d+\.\d+)/)[1];
      } else if (/Opera|OPR\/(\d+\.\d+)/.test(userAgent)) {
        browserName = "Opera";
        browserVersion = userAgent.match(/Opera|OPR\/(\d+\.\d+)/)[1];
      }

      // Determine Operating System
      let operatingSystem = "Unknown";
      if (platform.includes("Win")) operatingSystem = "Windows";
      else if (platform.includes("Mac")) operatingSystem = "macOS";
      else if (platform.includes("Linux")) operatingSystem = "Linux";
      else if (/Android/i.test(userAgent)) operatingSystem = "Android";
      else if (/iPhone|iPad|iPod/i.test(userAgent)) operatingSystem = "iOS";

      // Determine Device Type
      const deviceType = /Mobi|Android/i.test(userAgent) ? "Mobile" : "Desktop";

      return {
        browserName,
        browserVersion,
        operatingSystem,
        deviceType,
        userAgent,
      };
    };
    const browserDetails = getBrowserDetails();
    getIPData();
    setUserDetails(browserDetails);
    localStorage.setItem('userDetails', JSON.stringify(browserDetails))
    localStorage.setItem('ip', JSON.stringify(ipAddress))

  }, []);

  console.log(userDetails, userDetails)

  // const getUserDetails = async () => {
  //   localStorage.removeItem("userImpersonateData");

  //   if (account) {
  //     const userDetails = await instance.acquireTokenSilent({
  //       scopes: ['user.read'],
  //       account: account,
  //     });
  //     console.log("getUserDetails",userDetails);
  //     console.log("getUserDetails User Name",userDetails.account.username);
  //     // Redirect based on user's role here
  //     const userName = userDetails.account != null ? userDetails.account.username: "";
  //     let url = "";
  //      var objLoginUser = await checkUserLogin(userName);  

  //     if (objLoginUser !== null && objLoginUser.length > 0) {  
  //       const userData = {
  //         userName: objLoginUser[0].UserName,
  //         userRole: objLoginUser[0].RoleName,
  //         userEmail: objLoginUser[0].Email,
  //         userShortName: objLoginUser[0].FirstName.substring(0, 1) + objLoginUser[0].LastName.substring(0, 1),
  //         userId: objLoginUser[0].UserId,
  //       };
  //       localStorage.removeItem("reduxState");

  //       localStorage.setItem("userData", JSON.stringify(userData));

  //       if (objLoginUser[0].RoleName === "SuperAdmin" || objLoginUser[0].RoleName === "Admin") {
  //         url = "/dashboard";
  //         window.location.href = url;
  //       }
  //       else if (objLoginUser[0].RoleName === "DataProvider") {
  //         url = "/serviceprovider-dashboard";
  //         window.location.href = url;
  //       }
  //       else if (objLoginUser[0].RoleName === "Reviewer") {
  //         url = "/reviewer-dashboard";
  //         window.location.href = url;
  //       }
  //     }
  //     // else{
  //     //   url = "/unauthorizedaccess";
  //     //   window.location.href = url;
  //     // }
  //   }
  // };

  // useEffect(() => {
  //   getUserDetails();
  // }, [account]);

  ///////

  useEffect(() => {
    const getUserDetails = async () => {
      if (account) {
        try {
          const userDetails = await instance.acquireTokenSilent({
            scopes: ['user.read'],
            account: account,
          });
          const userName = userDetails.account.username || "";
          const user = await checkUserLogin(userName);
          if (user.length) {
            await updateLoginDetails(user)
            handleUserRedirection(user);
          } else {
            window.location.href = '/unauthorizedaccess'
          }
        } catch (error) {
          if (error.errorCode === 'login_required') {
            console.log("Silent sign-in failed, clearing cache and retrying:", error);
            //await instance.getTokenCache().clear();
            await instance.loginRedirect();
          } else {
            console.log("Error getting user details:", error);
          }
        }
      }
    };

    getUserDetails();
  }, [account, instance]);

  const checkAuthorisedUser = (url, roleName) => {
    const parts = url.split('?');
    const path = parts[0];
    const pathParts = path.split('/').filter(part => part !== '')[0];
    switch (pathParts) {
      case 'viewuploadedfiles':
        return (roleName === 'SuperAdmin' || roleName === 'Admin') ? url : '/unauthorizedaccess';
      case 'reviewer-dashboard':
        return (roleName === 'Reviewer') ? url : '/unauthorizedaccess';
      case 'serviceprovider-dashboard':
        return (roleName === 'DataProvider') ? url : '/unauthorizedaccess';
      default:
        return '/unauthorizedaccess';
    }

  }

  const handleUserRedirection = (user) => {
    if (user && user.length > 0) {
      const userData = {
        userName: user[0].UserName,
        userRole: user[0].RoleName,
        userEmail: user[0].Email,
        userShortName: user[0].FirstName.substring(0, 1) + user[0].LastName.substring(0, 1),
        userId: user[0].UserId,
      };
      localStorage.removeItem("reduxState");
      localStorage.setItem("userData", JSON.stringify(userData));

      let url = "/";
      const roleName = user[0].RoleName;
      const authorizedUrl = {
        SuperAdmin: '/dashboard',
        Admin: '/dashboard',
        DataProvider: '/serviceprovider-dashboard',
        Reviewer: '/reviewer-dashboard',
      };
      // switch (user[0].RoleName) {
      //   case "SuperAdmin":
      //   case "Admin":
      //     url = fromUrl ? checkAuthorisedUser(fromUrl , user[0].RoleName) :"/dashboard" ;
      //     break;
      //   case "DataProvider":
      //     url = fromUrl ? checkAuthorisedUser(fromUrl , user[0].RoleName) : "/serviceprovider-dashboard";
      //     break;
      //   case "Reviewer":
      //     url = fromUrl ? checkAuthorisedUser(fromUrl , user[0].RoleName) : "/reviewer-dashboard";
      //     break;
      //   default:
      //     url = "/unauthorizedaccess";
      // }
      url = fromUrl ? checkAuthorisedUser(fromUrl, roleName) : authorizedUrl[roleName];

      window.location.href = url || '/unauthorizedaccess';

    } else {
      console.log("No user found or unauthorized access");
    }
  };
  //

  const checkUserLogin = async (userName) => {
    var objUser;
    await UsersService.getUserDetailByEmail(userName).then((response) => {
      if (response.status !== 200) {
        throw new Error("Network response was not ok");
      }
      objUser = response.data.data;
    })
      .catch((error) => {
        objUser = null;
        console.error("Error fetching data from the API:", error);
      });

    return objUser;
  };
  const updateLoginDetails = async (user) => {
    const loginDetails = {
      UserId: user[0].UserId,
      SessionId: '',
      BrowserName: userDetails.browserName,
      BrowserVersion: userDetails.browserVersion,
      OperatingSystem: userDetails.operatingSystem,
      DeviceType: userDetails.deviceType,
      UserIPAddress: ipAddress
    }
    await UsersService.updateLoginDetails(loginDetails)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Network response was not ok");
        }

      })
      .catch((error) => {
        console.error("Error fetching data from the API:", error);
      });
  };


  return (
    <div>
    </div>
  );
};

export default Login;