import axios from '../configs/axiosConfig';
import appConfig from '../configs/appConfig';

const NOTIFICATION_API_BASE_URL = appConfig.API_BASE_URL + "email/history/";
const EMAIL_TEMPLATE_API_BASE_URL = appConfig.API_BASE_URL + "email/";

class NotificationService {
    getEmailHistoryNotRead(loginUserId, isForCount) {
        // Ensure isForCount is passed as integer
        const isForCountInt = parseInt(isForCount);
        const loginUserIdInt = parseInt(loginUserId);
        return axios.get(NOTIFICATION_API_BASE_URL + `notread?IsForCount=${isForCountInt}&LoginUserId=${loginUserIdInt}`);
    }

    getEmailTemplate(templateName) {
        return axios.get(EMAIL_TEMPLATE_API_BASE_URL + `template?TemplateName=${templateName}`);
    }

    updateEmailHistoryIsRead(EmailHistoryId, loginUserId) {
        // Ensure both IDs are passed as integers
        return axios.put(NOTIFICATION_API_BASE_URL + "isread", { 
            EmailHistoryId: parseInt(EmailHistoryId), 
            loginUserId: parseInt(loginUserId) 
        });
    }

    addEmailHistory() {
        return axios.post(NOTIFICATION_API_BASE_URL + "add");
    }
}

export default new NotificationService();