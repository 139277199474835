import React, { useState } from 'react';
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useMsal, useAccount } from '@azure/msal-react';
import ImpersonateModal from "./ImpersonateModal";
import usersService from "../../services/usersService";
import NotificationService from "../../services/notificationService";
import { withTranslation } from "react-i18next";
import CommonModuleService from "../../services/commonModuleService";
import i18n, { updateLanguage } from '../../i18n'; // Import i18n instance
import clientLogo from '../../assets/images/logo.svg'
import { msalConfig } from "../../configs/msalConfig";
import { fetchNotificationCount, fetchNotifications } from './notificationSlice';
import { connect } from 'react-redux';


function signOutClickHandler(instance) {
  const logoutUrl = msalConfig.auth.postLogoutRedirectUri;
  const logoutRequest = {
    account: instance.getActiveAccount(),
    postLogoutRedirectUri: logoutUrl,
  };
  instance.logoutRedirect(logoutRequest);
}

// SignOutButton Component returns a button that invokes a redirect logout when clicked
function SignOutButton() {
  // useMsal hook will return the PublicClientApplication instance you provided to MsalProvider
  const { instance } = useMsal();

  return (
    // <button onClick={() => signOutClickHandler(instance)}>Sign Out</button>
    <a className="dropdown-item" onClick={() => signOutClickHandler(instance)} >Log Out</a>
  );
}

class Header extends React.Component {
  constructor(props) {
    super(props);


    // Retrieve the object from the storage
    const data = localStorage.getItem("userData");
    const userData = JSON.parse(data);

    this.state = {
      userName: userData.userName,
      userRole: userData.userRole,
      userEmail: userData.userEmail,
      userShortName: userData.userShortName,
      activeItem: "",
      show: false,
      allUsersData: [],
      isForCount: false,
      languageOptions: [],
      language: ""
    };
    
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData?.userId) {
      this.props.fetchNotificationCount(userData.userId);
      this.props.fetchNotifications(userData.userId);
    }
    this.getUsers();
    this.fetchLanguageOptions();
  }

  async fetchNotifications() {
    try {
      const loginUser = JSON.parse(localStorage.getItem("userData"));
      let loginUserId = null;
  
      if (loginUser) {
        loginUserId = loginUser.userId;
      }
  
      NotificationService.getEmailHistoryNotRead(loginUserId, this.state.isForCount)
        .then((response) => {
          const notifications = response.data.data;
          const notReadEmails = notifications.length > 0 ? notifications[0].NotReadEmails : 0;
  
          this.setState({ notifications, actualNotificationCount: notReadEmails }, () => {
            console.log("Updated actualNotificationCount:", this.state.actualNotificationCount);
          });
        })
        .catch((error) => {
          console.error("Error fetching notifications:", error);
        });
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  }

  // handleNotificationClick = () => {
  //   // Fetch updated notifications data from the API with isForCount as 0
  //   this.setState({ isForCount: 0 }, () => {
  //     this.fetchNotifications();
  //   });
  // };

  handleLogout = () => {
    const { instance } = useMsal();
    instance.logoutRedirect();
    // Clear user data from local storage
    localStorage.removeItem("userData");
  };

  handleClose = () => {
    this.setState({
      show: false,
    });

    window.location.reload();
  };

  handleShow = () => {
    this.setState({
      show: true,
    });
  };

  async getUsers() {
    await usersService
      .getUsers()
      .then((response) => {
        const loginUser = JSON.parse(localStorage.getItem("userData"));
        if (loginUser.userRole === "Admin") {
          const filterData = response.data.data.filter(
            (data) =>
              data.UserId != loginUser.userId &&
              data.RoleName !== loginUser.userRole &&
              data.RoleName !== "SuperAdmin"
          );
          this.setState({ allUsersData: filterData });
        } else { //Super Admin
          const filterData = response.data.data.filter(
            (data) =>
              data.UserId != loginUser.userId &&
              data.RoleName !== loginUser.userRole
          );

          this.setState({ allUsersData: filterData });
        }
      })
      .catch((error) => console.log(error));
  }

  async getLanguage() {
    await CommonModuleService
      .getLanguage()
      .then((response) => {
        const languageValue = response.data.data;
        this.setState(languageValue);
      })
      .catch((error) => console.log(error));
  }

  async fetchLanguageOptions() {
    try {
      const response = await CommonModuleService.getLanguage();
      const languageOptions = response.data.data;
      this.setState({ languageOptions });
      
      const language = languageOptions.filter((item) => item.IsDefault === true);
      
      this.handleLanguageChange(language[0].LanguageCode,languageOptions);   

    } catch (error) {
      console.error("Error fetching language options:", error);
    }
  }

  removeImpersonate = () => {
    const impersonateData = localStorage.getItem("userImpersonateData");
    localStorage.removeItem("userImpersonateData");

    localStorage.setItem("userData", impersonateData);

    window.location.reload();
  };

  toggleIsForCount = () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    this.setState(
      prevState => ({
        isForCount: !prevState.isForCount
      }),
      () => {
        // Refresh notifications when dropdown is opened
        this.props.fetchNotifications(userData.userId);
      }
    );
  };

   handleLanguageChange = (value,options) => {
      //const selectedLanguage = event.target.value;
      const selectedLanguage = value;
      this.setState({ language: selectedLanguage });
      let language

      //console.log("handleLanguageChange options",options)
      if (options){
        language = options.filter((item) => item.LanguageCode === selectedLanguage)
      }else{
        language = this.state.languageOptions.filter((item) => item.LanguageCode === selectedLanguage);
      }
      // const language = this.state.languageOptions.filter((item) => item.LanguageCode === selectedLanguage);
      localStorage.setItem("language", JSON.stringify(language[0]));
      updateLanguage(selectedLanguage);

      // console.log("languageId ", language[0].LanguageId);
  };

  handleClick() {
    const { isActive } = this.state;
    this.setState({ isActive: !isActive }, () => {
      document.body.classList.toggle("activeMenu", this.state.isActive);
    });
  }



  render() {

    const { userName, userRole, userEmail, userShortName, language, languageOptions } = this.state;
    const { notifications, notificationCount: actualNotificationCount, t } = this.props;
    console.log("actualNotificationCount",actualNotificationCount);
    const userImpersonateData =
      JSON.parse(localStorage.getItem("userImpersonateData")) || false;

    return (
      <>
        <header className="container-fluid">
          <nav className="navbar navbar-expand-lg">
            <a className="nav-icon me-3" id="menu" onClick={this.handleClick}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Burger_icon"
                data-name="Burger icon"
                width="26"
                height="24"
                viewBox="0 0 26 24"
              >
                <rect
                  id="Rectangle_175"
                  data-name="Rectangle 175"
                  height="2"
                  rx="2"
                  fill="#152838"
                />
                <rect
                  id="Rectangle_176"
                  data-name="Rectangle 176"
                  height="2"
                  rx="2"
                  transform="translate(0 10)"
                  fill="#152838"
                />
                <rect
                  id="Rectangle_177"
                  data-name="Rectangle 177"
                  height="2"
                  rx="2"
                  transform="translate(0 20)"
                  fill="#152838"
                />
              </svg>
            </a>
            <a className="navbar-brand p-0 clientLogo" href="#">
              <img src={clientLogo} />
            </a>

            <div className="collapse navbar-collapse justify-content-end" id="">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item mx-2 mt-1 language-dropdown">
                  <select value={language} onChange={(e) => {
                                    this.handleLanguageChange(e.target.value);
                                  }} className="form-select form-select-sm">
                    {languageOptions.map(option => (
                      <option key={option.LanguageId} value={option.LanguageCode}>
                        {option.LanguageName}
                      </option>
                    ))}
                  </select>
                </li>
                <li className="nav-item mx-2 mt-1 notification">
        <a
          className="nav-link"
          href="#"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          onClick={this.toggleIsForCount}
        >
          <img src="../assets/images/iconNoti.svg" width="25" />
          
            <sup style={{ fontSize: "18px" }}>
              <Badge pill variant="danger">
              {actualNotificationCount > 0 ? actualNotificationCount : 0}
              </Badge>
            </sup>
        </a>

        <div className="dropdown-menu p-2">
          <div className="row m-0 justify-content-between">
            <div className="col-auto px-2"></div>
            <div className="col-auto px-2">
              <Link className="" to="/viewNotifications">
                {t("Notifications.View_all")}
              </Link>
            </div>
          </div>
          <div className="row m-0">
            <ul style={{ listStyleType: 'disc', color: 'white' }}>
              {notifications.slice(0, 5).map((notification) => (
                <li 
                  key={notification.EmailHistoryId} 
                  style={{ marginBottom: '10px', marginLeft: '20px' }}
                >
                  <div className="col-12 py-2 px-3 notificationBox my-1">
                    <p>
                      {notification.Subject}
                      <br />
                      {notification.CreatedDate}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </li>

                <li className="nav-item ml-2 btn-group userMenu">
                  <div
                    className="userProfile"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className="userPhoto">
                      <span>{userShortName}</span>
                    </div>
                    <div className="userDetails">
                      <div className="userName">{userName}</div>
                      <div className="userEmail">{userEmail}</div>
                    </div>
                    <div
                      className="userArrow"
                    >
                      <img src="../assets/images/arrow.svg" className="no-click" />
                    </div>
                  </div>
                  <div className="dropdown-menu">
                    <ul className="p-0">
                      <li>
                        <Link className="dropdown-item" to="/user-profile">
                          {t("Header.Profile")}
                        </Link>
                      </li>
                      <li>
                        <hr className="dropdown-divider m-0" />
                      </li>

                      <li
                        style={{
                          display:
                            (JSON.parse(localStorage.getItem("userData")).userRole === "Reviewer" ||
                              JSON.parse(localStorage.getItem("userData")).userRole === "DataProvider") ||
                              Object.keys(userImpersonateData).length !== 0
                              ? "none"
                              : "block",
                        }}
                      >
                        <a
                          className="dropdown-item"
                          onClick={() => {
                            this.handleShow();

                          }}
                        >
                          {t("Header.Impersonate")}
                        </a>
                      </li>

                      <li
                        style={{
                          display:
                            localStorage.getItem("userImpersonateData") ? "block" : "none",
                        }}
                      >
                        <a
                          className="dropdown-item"
                          onClick={() => this.removeImpersonate()}
                        >
                          {t("Header.Exit_Impersonate")}
                        </a>
                      </li>

                      <li>
                        <hr className="dropdown-divider m-0" />
                      </li>
                      <li>
                        {" "}
                        {/* <a
                          className="dropdown-item"
                          onClick={this.handleLogout}
                          href="/"
                        >
                          Log Out
                        </a> */}
                        <SignOutButton />
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </header>

        <ImpersonateModal
          show={this.state.show}
          allUsersData={this.state.allUsersData}
          handleClose={this.handleClose}
        />
      </>
      
    );
    
  }
}

const mapStateToProps = (state) => ({
  notifications: state.notifications.notifications,
  notificationCount: state.notifications.count,
  loading: state.notifications.loading,
  error: state.notifications.error
});

const mapDispatchToProps = {
  fetchNotificationCount,
  fetchNotifications
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Header));